<template>
  <gl-modal
    v-bind="$attrs"
    capitalize-title
    full-buttons
    :submit-title="isCreate ? 'Create' : 'Edit'"
    :title="isCreate ? 'Create a new user' : 'Edit user'"
    width="450"
    v-on="$listeners"
    @close="$emit('close')"
    @submit="onSubmit"
  >
    <div
      v-if="isCreate"
      class="fs-14 bold mb-3"
    >
      Add user’s email and assign a role.<br>
      Your invitee will receive the registration link.
    </div>
    <div class="gl-input__label">
      User role
    </div>
    <vSelect
      v-model="roleData"
      class="grey-select"
      :class="{ 'gl-modal__row': role !== 'admin' }"
      :clearable="false"
      :disabled="!isCreate && roleData.value === 'customer'"
      :options="options"
    />
    <div
      v-if="role === 'admin'"
      class="flex mb-3 mt-1"
    >
      <gl-icon
        class="mr-2 gl-icon--disabled"
        :height="24"
        name="def-report"
        :width="24"
      />
      <div class="grey-text">
        Attention: admin will have a full access to user & tag management
      </div>
    </div>
    <gl-input
      v-model="email"
      class="gl-modal__row"
      :disabled="!isCreate && roleData.value === 'customer'"
      :height="40"
      label="user email"
      name="Email"
      placeholder="usermail@email.com"
      rules="required|email"
      type="email"
      vid="email"
    />
    <gl-input
      v-model="companyName"
      class="gl-modal__row"
      :height="40"
      label="user company name"
      name="Company name"
      placeholder="Add company name"
      rules="required|min:1|max:255|companyName"
      type="text"
    />
    <div class="gl-input__label">
      Expiration date
    </div>
    <date-picker
      ref="datePicker"
      v-model="activeTo"
      class="mb-4 users-date-picker"
      :disabled-date="disabledRange"
      format="DD.MM.YYYY"
      :placeholder="activeTo === null || (!activeTo && !isCreate) ? 'Unlimited' : 'Select date'"
      style="height: 40px"
      type="date"
      value-type="timestamp"
    >
      <template v-slot:footer>
        <div class="flex flex-end">
          <div>
            <button
              class="mx-btn mx-btn-text cancel-date-range mr-2"
              @click="activeTo = null"
            >
              Unlimited
            </button>
          </div>
        </div>
      </template>
    </date-picker>
    <div class="flex align-center">
      <gl-input
        v-model="requestsTotal"
        v-mask="'#####'"
        class="gl-modal__row flex-1 mr-2"
        :height="40"
        label="Update Total Granted Requests"
        name="requestsTotal"
        placeholder="100"
        rules="min_value:0"
        type="number"
        vid="requestsTotal"
      />
      <gl-active
        v-model="requestsTotalSupport"
        label="Unlimited"
        @input="handleRequestsTotalSupport"
      />
    </div>
    <gl-checkbox
      v-if="!isCreate"
      class="fs-14"
      dark
      label="Update Current Request Balance"
      :value="needUpdateNumberOfRequest"
      @input="needUpdateNumberOfRequest = !needUpdateNumberOfRequest"
    />
  </gl-modal>
</template>

<script>
// Components
import GlModal from '@/components/gl-modal'
import GlIcon from '@/components/gl-icon'
import GlInput from '@/components/gl-input'
import vSelect from 'vue-select'
import GlActive from '@/components/gl-active.vue'
// Utils
import { formatDate } from "@/utils/format-date";
import moment from "moment";
// Vuex
import { mapGetters } from 'vuex'
import GlCheckbox from '@/components/gl-checkbox.vue'

export default {
  components: {
    GlCheckbox,
    GlActive,
    GlIcon,
    GlModal,
    GlInput,
    vSelect,
  },
  inheritAttrs: false,
  props: {
    searchMode: {
      type: String,
      default: 'tx',
    },
    isCreate: {
      type: Boolean,
      default: true,
    },
    email: {
      type: String,
      default: '',
    },
    activeTo: {
      type: [Number, String],
      default: '',
    },
    role: {
      type: String,
      default: ''
    },
    companyName: {
      type: String,
      default: ''
    },
    requestsTotal: {
      type: [Number, String],
      default: ''
    },
    requestsMade: {
      type: [Number, String],
      default: ''
    }
  },
  data() {
    return {
      needUpdateNumberOfRequest: false,
      roleData: null,
      requestsTotalSupport: false,
      options: [
        {
          label: 'Regular user',
          value: 'customer'
        },
        {
          label: 'Admin',
          value: 'admin'
        },
        {
          label: 'Sales Manager',
          value: 'sales-manager'
        },
      ],
    }
  },
  computed: {
    ...mapGetters('user', ['isSales']),
    rules() {
      return `required`
    },
  },
  watch: {
    requestsTotal: {
      handler(newVal) {
        this.requestsTotalSupport = (newVal || newVal > 0);
      },
      immediate: true
    },
  },
  mounted() {
    this.roleData = this.options.find(({ value }) => value === this.role) || {
      label: 'Regular user',
      value: 'customer'
    }

    if (this.isSales) {
      this.options = this.options.filter((op) => op.value !== 'admin' && op.value !== 'sales-manager')
    }
  },
  methods: {
    formatDate,
    disabledRange(date) {
      return date < moment().endOf('day');
    },
    handleRequestsTotalSupport(val) {
      if (!val) this.requestsTotal = ''
      // this.needUpdateNumberOfRequest = true
    },
    onSubmit() {
      const payload = {
        email: this.email,
        requestsTotal: this.needUpdateNumberOfRequest ? (Number(this.requestsTotal)) || null : undefined,
        requestsLeft: this.needUpdateNumberOfRequest ? (Number(this.requestsTotal)) || null : undefined,
        companyName: this.companyName.trim(),
        role: this.roleData.value,
        activeTo: this.activeTo
          ? moment(this.activeTo).valueOf()
          : null
      }

      if (this.isCreate) {
        this.$emit('add', payload)
      } else {
        this.$emit('update', payload)
      }
    },
  },
}
</script>

<style>
.users-date-picker .mx-input {
  height: 40px !important;
}
</style>
